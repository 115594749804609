<template>
  <div class="record-component">
    <div class="desktop-photos">
      <template v-if="sortedImages.length">
        <image-item
          :src="getImageUrl(firstImage.url)"
          :selectable="imagesSelectable"
          :is-selected="isImageSelected(firstImage.id)"
          :class="{classified: isClassified}"
          @click="onClickImage(firstImage)"
          @select="onSelectImage(firstImage)"
        >
          <div v-if="isClassified" class="classified-subscribe">
            <slot name="subscription-link"></slot>
          </div>
        </image-item>

        <div class="images-container" v-if="otherImages.length">
          <image-item
            v-for="(image, index) in otherImages"
            :selectable="imagesSelectable"
            :is-selected="isImageSelected(image.id)"
            :key="image.url"
            :src="getImageUrl(image.url)"
            :overlay-text="getOverlayText(image, index, otherImages.length)"
            :class="{classified: isClassified}"
            @click="onClickImage(image)"
            @select="onSelectImage(image)"
          ></image-item>
        </div>
        <select-all-icon
          v-if="images.length > 1 && imagesSelectable"
          title="Select all"
          class="select-all-icon"
          :size="50"
          @click="selectAllImagesClick"
        ></select-all-icon>
      </template>
      <external-source-logo
        v-else-if="isExternalSource && userIsLoggedInState"
        :has-attachments="source.has_external_attachments"
        :show-text="true"
        :url="source.external_source_url"
      ></external-source-logo>
      <div v-else class="no-image">No Photos Available</div>
      <div class="images-controls" v-if="imagesSelectable">
        <mcr-button v-if="selectedImageIds.length" class="remove-images-button" @click="deleteSelectedImages"
          >Delete Selected Images</mcr-button
        >
      </div>
    </div>

    <div class="details">
      <div class="record-header">
        <div class="supplemental" v-if="!hasPrimaryPerson">{{ recordTypeDisplay }} of</div>
        <h4 v-if="fullNameEn || fullNameCh">{{ fullNameEn }} {{ fullNameCh }}</h4>
        <h4 v-else>Unknown</h4>
        <div class="lifetime-label value" v-if="!isDataProtected" :class="{concealed: isClassified}">
          {{ lifeYears }}
        </div>
        <div class="supplemental primary-persons" v-if="hasPrimaryPerson">
          <span>on the {{ recordTypeDisplay }} of </span>
          <router-link
            class="person-link"
            :to="getPersonRecordDetailRoute(p.code, p.full_name)"
            :key="p.code"
            v-for="p in source.primary_persons"
            >{{ p.full_name }}</router-link
          >
        </div>
        <p v-if="sourceCollections.length" class="source-name">
          in
          <router-link
            v-for="collection in sourceCollections"
            :key="collection.pk"
            class="name"
            :to="getCollectionRoute(collection)"
            >{{ collection.name }}</router-link
          >
        </p>
        <p v-else-if="source" class="source-name">
          in <router-link :to="sourceRoute">{{ sourceTitle }}</router-link>
        </p>
        <div v-if="isClassified" class="">
          <slot name="subscription-link"></slot>
        </div>
        <div class="action-buttons" v-else>
          <mcr-button
            v-if="source.external_source_url && userIsLoggedInState"
            class="action-button"
            :href="source.external_source_url"
            target="_blank"
            >{{ openExternalLabel }}</mcr-button
          >
          <mcr-button-router-link
            v-if="sortedImages.length && !isClassified && !isDataProtected"
            class="action-button"
            :to="viewerRoute"
            >{{ openViewerLabel }}</mcr-button-router-link
          >

          <slot name="header-buttons"></slot>
        </div>
      </div>

      <slot name="admin"></slot>

      <div v-if="description && !isDataProtected" class="description value" :class="{concealed: isClassified}">
        {{ description }}
      </div>

      <div v-if="personNotes && !isDataProtected" class="description value">
        <b>Biography / Notes: </b><span :class="{concealed: isClassified}">{{ personNotes }}</span>
      </div>

      <div class="mobile-photos" v-if="sortedImages.length">
        <div class="images-container">
          <image-item
            v-for="(image, index) in sortedImages"
            :selectable="imagesSelectable"
            :is-selected="isImageSelected(image.id)"
            :key="image.url"
            :src="getImageUrl(image.url)"
            :class="{classified: isClassified}"
            :overlay-text="getOverlayText(image, index, sortedImages.length)"
            @click="onClickImage(image)"
            @select="onSelectImage(image)"
          ></image-item>
        </div>
        <div class="images-controls">
          <mcr-button v-if="selectedImageIds.length" class="remove-images-button" @click="deleteSelectedImages"
            >Delete Selected</mcr-button
          >
        </div>
      </div>
      <div class="mobile-photos" v-else-if="isExternalSource">
        <external-source-logo
          :has-attachments="source.has_external_attachments"
          :show-text="true"
          :url="source.external_source_url"
        ></external-source-logo>
      </div>
      <div class="mobile-photos no-photos" v-else>No photos available</div>

      <slot></slot>

      <p class="supplemental" v-if="dataProvider">
        Record made available courtesy of the
        <a :href="dataProviderUrl" target="_blank" rel="nofollow">{{ dataProvider }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import ExternalSourceLogo from '@common/elements/images/ExternalSourceLogo';
import ImageItem from '@common/pages/personRecords/ImageItem';
import {sourceTypes} from '@common/utils/consts.source';
import {getApproximateYear} from '@common/utils/utils';
import {getCemeteryDetailRoute, getPersonRecordDetailRoute, getSourceCollectionRoute} from '@common/utils/utils.routes';
import {hasClassifiedData} from '@common/utils/utils.sources';
import {sortedUniqBy} from 'lodash';
import SelectAllIcon from 'vue-material-design-icons/CheckboxMultipleMarkedCircle';
import {mapGetters} from 'vuex';

import personRecordsPage from './js/person-records-page';

export default {
  props: {
    images: Array,
    imagesCount: Number,
    recordTypeDisplay: String,
    dataProvider: String,
    dataProviderUrl: String,
    source: Object,
    description: String,
    personNotes: String,

    imagesSelectable: Boolean,

    fullNameEn: String,
    fullNameCh: String,
    birthDate: Object,
    deathDate: Object,
    isDataProtected: Boolean,
    isClassified: {type: Boolean, default: false},
  },
  data() {
    return {
      selectedImageIds: [],
      openExternalLabel: 'Open in Partner Site',
      openViewerLabel: 'View in Original Record',
      maxConcealedLines: 10,
    };
  },
  mounted() {
    if (this.isClassified) {
      let spans = document.querySelectorAll('.record-metadata-table .cell');
      for (let item of spans) {
        if (item && item.innerText && hasClassifiedData(item.innerText)) {
          let linesCounter = 0;

          const lines = item.innerText.split('\n');
          let results = [];
          let emptyLinesStack = [];

          for (let line of lines) {
            linesCounter++;
            if (linesCounter >= this.maxConcealedLines) {
              break;
            }
            let new_lines = [];
            const words = line.split(' ');
            for (let w of words) {
              new_lines.push('<span class="concealed">' + w + '</span>');
            }

            const newLineText = new_lines.join(' ');
            if (newLineText === '<span></span>') {
              emptyLinesStack.push(newLineText);
            } else {
              results = [...results, ...emptyLinesStack, newLineText];
              emptyLinesStack = [];
            }
          }
          item.innerHTML = results.join('\n');
          item.classList.add('classified');
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userIsLoggedInState']),
    sourceTitle() {
      return this.source.full_title;
    },
    sourceRoute() {
      if (this.source.type === sourceTypes.CEMETERY && this.source.cemeteries && this.source.cemeteries.length) {
        const cemetery = this.source.cemeteries[0];
        return getCemeteryDetailRoute(cemetery.object_id, cemetery.name);
      }
      if (this.source.clan_pedigree_id) {
        return {name: 'search-tool-zupu-detail', params: {zupuId: this.source.clan_pedigree_id}};
      }
      return {name: 'source-detail', params: {sourceId: this.source.id}};
    },
    lifeYears() {
      const unknown = 'Unknown';
      const birth =
        this.birthDate && this.birthDate.year
          ? getApproximateYear(
              this.birthDate.year,
              this.birthDate.approximation_years,
              this.birthDate.approximation_type
            )
          : unknown;
      const death =
        this.deathDate && this.deathDate.year
          ? getApproximateYear(
              this.deathDate.year,
              this.deathDate.approximation_years,
              this.deathDate.approximation_type
            )
          : unknown;
      if (birth === unknown && death === unknown) {
        return '';
      }
      return `${birth} - ${death}`;
    },
    sortedImages() {
      if (this.images.length < 2) {
        return this.images;
      }
      const images = sortedUniqBy(
        [...this.images].sort((a, b) => a.priority - b.priority),
        'url'
      );
      if (this.$store.getters.windowWidthState <= this.$breakpoints.tablet) {
        return images.slice(0, 6);
      }
      return images;
    },
    firstImage() {
      return this.sortedImages[0];
    },
    otherImages() {
      return this.sortedImages.slice(1);
    },
    isExternalSource() {
      return this.source.is_external;
    },
    moreImagesCount() {
      return this.imagesCount - this.sortedImages.length;
    },
    viewerRoute() {
      return personRecordsPage.getViewerRoute(
        this.firstImage.priority,
        this.firstImage.id,
        this.source.id,
        this.source.clan_pedigree_id
      );
    },
    hasPrimaryPerson() {
      return this.source.primary_persons.length > 0;
    },
    sourceCollections() {
      return this.source.collections || [];
    },
  },
  methods: {
    getPersonRecordDetailRoute,
    onClickImage(image) {
      if (this.isClassified) {
        return false;
      }
      this.$emit('click-image', {image, sourceId: this.source.id, zupuId: this.source.clan_pedigree_id});
    },
    onSelectImage(image) {
      if (this.isClassified) {
        return false;
      }
      const index = this.selectedImageIds.indexOf(image.id);
      index > -1 ? this.selectedImageIds.splice(index, 1) : this.selectedImageIds.push(image.id);
    },
    isImageSelected(id) {
      return this.selectedImageIds.includes(id);
    },
    selectAllImagesClick() {
      this.selectedImageIds = this.selectedImageIds.length ? [] : this.images.map(image => image.id);
    },
    cancelSelection() {
      this.selectedImageIds = [];
    },
    deleteSelectedImages() {
      this.$emit('delete-images', this.selectedImageIds);
    },
    getOverlayText(image, index, arrayLength) {
      if (this.moreImagesCount && index === arrayLength - 1) {
        return `+ ${this.moreImagesCount + 1} Images`;
      }
    },
    getImageUrl(src) {
      if (src && src.startsWith('/media')) {
        return `${process.env.VUE_APP_MEDIA_BASE}${src}`;
      }
      return src;
    },
    getCollectionRoute(collection) {
      return getSourceCollectionRoute(collection);
    },
  },
  components: {
    ExternalSourceLogo,
    McrButton,
    McrButtonRouterLink,
    ImageItem,
    SelectAllIcon,
  },
};
</script>

<style lang="scss" scoped>
.record-component::v-deep .classified {
  &.image-item {
    .classified-subscribe {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      min-height: 200px;

      div {
        pointer-events: all;
      }
    }

    .overlay {
      display: none;
    }

    img {
      $blurValue: 7;
      -webkit-filter: blur(#{$blurValue}px) brightness(50%);
      -moz-filter: blur(#{$blurValue}px) brightness(50%);
      -o-filter: blur(#{$blurValue}px) brightness(50%);
      -ms-filter: blur(#{$blurValue}px) brightness(50%);
      filter: blur(#{$blurValue}px) brightness(50%);
    }
  }
}
.record-component::v-deep .concealed {
  cursor: default;
}

.record-component {
  display: flex;
  flex-direction: row;

  h4 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .lifetime-label {
    color: $text-alternate-color;
  }

  .lifetime-label + .primary-persons {
    margin-top: 8px;
  }

  .primary-persons {
    a + a:last-child {
      &::before {
        display: inline-block;
        content: ' and ';
        margin: 0 5px;
      }
    }
    a:nth-of-type(n + 2):not(:last-child) {
      &::before {
        display: inline-block;
        content: ', ';
        margin-right: 5px;
      }
    }
  }

  .select-all-icon {
    position: absolute;
    z-index: 2;
    top: 60px;
    right: 10px;
    opacity: 0.2;
    display: none;
    color: white;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .action-buttons {
    display: flex;
    margin: 20px 0;
    > * {
      margin-right: 20px;
    }
    @media only screen and (max-width: $breakpoint-phablet) {
      flex-direction: column;
      > * {
        margin: 10px 0;
      }
    }
  }
  .desktop-photos {
    margin-right: 48px;
    width: 40%;
    min-width: 280px;
    flex-shrink: 0;
    position: relative;

    &:hover {
      .select-all-icon {
        display: flex;
      }
    }

    .no-image {
      background: $background-label;
      color: $supplemental-text-color;
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
  }
  .mobile-photos {
    display: none;
  }
  .images-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    margin-top: 10px;
    &::v-deep img {
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }
  .mobile-photos.no-photos {
    border-top: 1px solid $divider-line-color;
    border-bottom: 1px solid $divider-line-color;
    color: $supplemental-text-color;
    padding: 20px 0;
  }
  .images-controls {
    margin: 10px 0;
  }

  .checkmark-list {
    margin: 20px 0;
  }

  .details {
    flex-grow: 1;

    .source-name {
      word-break: break-word;

      .name:not(:last-child)::after {
        content: ', ';
      }
    }
  }

  .view-transcription-link {
    margin-left: 5px;
  }

  .description {
    white-space: pre-line;
    margin: 20px 0;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
    .desktop-photos {
      display: none;
    }
    .mobile-photos {
      display: block;
      margin: 30px 0 50px;
    }
  }
}
</style>
